<template>
  <el-card>
    <data-viewer
        :create="create"
        :filter="filter"
        :refresh="refresh"
        :source="source"
        :thead="thead"
        :title="title"
        v-on:refreshFalse="refreshData(false)"
    >
      <template slot-scope="props">
        <tr @click.prevent.stop="createLink($event, props.item)">
          <td>{{ props.item.NrR }}</td>
          <td>{{ props.item.Alias }}</td>
          <td>{{ props.item.Wart | cash }}</td>
          <td>{{ props.item.DWy }}</td>
          <td>
            <select
                class="form-control"
                v-on:change="changeStatus($event, props.item)"
            >
              <option
                  :selected="(props.item.Dpl)"
                  value="true"
              >
                {{ $t('system.paid') }}
              </option>
              <option
                  :selected="(!props.item.Dpl)"
                  value="false"
              >
                {{ $t('system.not_paid') }}
              </option>
            </select>
          </td>
          <td>{{ props.item.Dpl }}</td>
        </tr>
      </template>
    </data-viewer>
  </el-card>
</template>

<script>
import axios from 'axios';
import DataViewer from '../../components/DataViewerInvoice.vue';
import notification from '../../notification/notify';

export default {
  name: 'InvoiceIndex',
  data() {
    return {
      title: 'Invoices',
      resource: 'invoice',
      source: 'client_invoice',
      create: '/invoice/create',
      refresh: false,
      thead: [
        {title: this.$t('invoice.nrr'), key: 'NrR', sort: true},
        {title: this.$t('invoice.alias'), key: 'Alias', sort: true},
        {title: this.$t('invoice.wart'), key: 'Wart', sort: true},
        {title: this.$t('invoice.dwy'), key: 'DWy', sort: true},
        {title: this.$t('invoice.payment_status'), key: 'Dpl', sort: true},
        {title: this.$t('invoice.dpl'), key: 'Dpl', sort: true},
      ],
      filter: [
        'NrR', 'Alias', 'Wart', 'DWy', 'Dpl',
      ],
    };
  },
  components: {
    DataViewer,
  },
  beforeMount() {
    this.$store.commit('SET_ACTIVE', 'invoice');
  },
  methods: {
    fileUploadSuccess() {
    },
    dropzoneLanguage() {
      return {
        dictDefaultMessage: this.$t('system.drop_files_to_upload'),
        dictFileTooBig: this.$t('system.file_is_to_big'),
        dictRemoveFile: this.$t('system.remove_file'),
      };
    },
    refreshData(status) {
      this.refresh = status;
    },
    changeStatus(event, item) {
      let data = {
        status: event.target.value,
      };
      var vm = this;
      axios.put('invoice/change_status/' + item.id, data)
          .then(function (response) {
            if (response.data.saved) {
              notification.notify(
                  vm.$t('notify.success'),
                  response.data.message,
                  'success');
              vm.refresh = true;
            } else {
              notification.notify(
                  vm.$t('notify.error'),
                  response.data.message,
                  'error');
            }
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    createLink(event, item) {
      if (event.target.tagName !== 'SELECT') {
        this.$router.push('/invoice/' + item.id);
      }
    },
  },
};
</script>
