<template>
  <div class="panel panel-default box box-primary">
    <div class="panel-body">
      <div class="button-row row">
        <div class="col-sm-6">
          <select
              @change="fetchData()"
              class="form-control"
              v-model="params.invoicesFilter"
          >
            <option value="paid">{{ $t('system.paid') }}</option>
            <option value="not_paid">{{ $t('system.not_paid') }}</option>
            <option value="all">{{ $t('system.all') }}</option>
          </select>
        </div>
      </div>
      <div
          class="filter"
          v-if="showFilter"
      >
        <div class="filter-operator">
          <select
              class="form-control"
              v-model="params.search_operator"
          >
            <option
                :value="key"
                v-bind:key="key"
                v-for="(value, key) in operators"
            >{{ value }}
            </option>
          </select>
        </div>

        <div class="panel-footer pagination-footer">
          <div class="pagination-item per-page">
            <span>{{ $t('system.per_page') }} </span>
            <select
                @change="fetchData"
                v-model="params.per_page"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>300</option>
            </select>
          </div>
          <div class="pagination-item">
            <span>{{ $t('system.showing') }} {{ model.from }} - {{ model.to }} na {{ model.total }}</span>
          </div>
          <div class="pagination-item">
            <span>{{ $t('system.current_page') }} </span>
            <input
                @keyup.enter="fetchData"
                class="pagination-input"
                type="text"
                v-model="params.page"
            >
            <span> na {{ model.last_page }}</span>
          </div>
          <div class="pagination-item">
            <button
                @click="prev"
                class="btn btn-default btn-sm"
            >{{ $t('system.prev') }}
            </button>
            <button
                @click="next"
                class="btn btn-default btn-sm"
            >{{ $t('system.next') }}
            </button>
          </div>
        </div>
        <div class="filter-input">
          <input
              @keyup.enter="fetchData"
              class="form-control"
              placeholder="Search"
              type="text"
              v-model="params.search_query_1"
          >
        </div>
        <div
            class="filter-input"
            v-if="params.search_operator === 'between'"
        >
          <input
              @keyup.enter="fetchData"
              class="form-control"
              placeholder="Search"
              type="text"
              v-model="params.search_query_2"
          >
        </div>
        <div class="filter-btn">
          <button
              @click="fetchData"
              class="btn btn-primary btn-sm btn-block"
          >Filter
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table
            class="table table-striped table-bordered table-hover"
            v-if="models > 0"
        >
          <thead>
          <tr>
            <th
                v-bind:key="item.id"
                v-for="item in thead"
            >
              <div
                  @click="sort(item.key)"
                  class="dataviewer-th"
                  v-if="item.sort"
              >
                <span>{{ item.title }}</span>
                <span v-if="params.column === item.key">
                                      <span
                                          class="table-asc"
                                          v-if="params.direction === 'asc'"
                                      >&#x25B2;</span>
                <span
                    class="table-desc"
                    v-else
                >&#x25BC;</span>
                </span>
                <span
                    class="glyphicon glyphicon-sort table-norm"
                    v-else
                ></span>
              </div>
              <div v-else>
                <span>{{ item.title }}</span>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <slot
              :item="item"
              v-for="item in model.data"
          ></slot>
          </tbody>

        </table>
      </div>
      <div
          class="callout callout-info"
          v-if="models < 1"
      >
        <h6>{{ $t('system.not_found') }}</h6>
        <p></p>
      </div>
    </div>
    <div class="panel-footer pagination-footer">
      <div class="pagination-item per-page">
        <span>{{ $t('system.per_page') }} </span>
        <select
            @change="fetchData"
            v-model="params.per_page"
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>300</option>
        </select>
      </div>
      <div class="pagination-item">
        <span>{{ $t('system.showing') }} {{ model.from }} - {{ model.to }} na {{ model.total }}</span>
      </div>
      <div class="pagination-item">
        <span>{{ $t('system.current_page') }} </span>
        <input
            @keyup.enter="fetchData"
            class="pagination-input"
            type="text"
            v-model="params.page"
        >
        <span> {{ $t('system.of') }} {{ model.last_page }}</span>
      </div>
      <div class="pagination-item">
        <button
            @click="prev"
            class="btn btn-default btn-sm"
        >{{ $t('system.prev') }}
        </button>
        <button
            @click="next"
            class="btn btn-default btn-sm"
        >{{ $t('system.next') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import notification from '../notification/notify';

export default {
  props: ['source', 'thead', 'filter', 'create', 'title', 'createPermission', 'refresh'],
  computed: {
    createPermissionComp: function () {
      if (this.createPermission) {
        return this.createPermission;
      } else {
        return null;
      }
    },
    models: function () {
      if (typeof this.model.data !== 'undefined') {
        return this.model.data.length;
      }
      return 0;
    },
  },
  data() {
    return {
      showFilter: false,
      model: {
        data: [],
      },
      params: {
        column: 'id',
        direction: 'desc',
        per_page: 100,
        page: 1,
        search_column: 'id',
        search_operator: 'equal_to',
        search_query_1: '',
        search_query_2: '',
        invoicesFilter: 'all',
      },
      operators: {
        equal_to: '=',
        not_equal: '<>',
        less_than: '<',
        greater_than: '>',
        less_than_or_equal_to: '<=',
        greater_than_or_equal_to: '>=',
        in: 'IN',
        not_in: 'NOT IN',
        like: 'LIKE',
        between: 'BETWEEN',
      },
    };
  },
  beforeMount() {
    this.fetchData();
  },
  watch: {
    'refresh': function () {
      this.$emit('refreshFalse');
      this.fetchData();
    },
  },
  methods: {
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchData();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchData();
      }
    },
    sort(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchData();
    },
    fetchData() {
      var vm = this;
      axios.get(this.buildURL())
          .then(function (response) {
            Vue.set(vm.$data, 'model', response.data.model);
            vm.$emit('dataFetched', response.data.model.data);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    buildURL() {
      var p = this.params;
      return `${this.source}?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}&search_column=${p.search_column}&search_operator=${p.search_operator}&search_query_1=${p.search_query_1}&search_query_2=${p.search_query_2}&invoicesFilter=${p.invoicesFilter}`;
    },
  },
};
</script>
